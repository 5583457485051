import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent implements OnInit {

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  // Stub method for opening a dialog
  openDialog(): void {
    // For demonstration, you might simply show an alert.
    // You can later replace this with a call to this.dialog.open(SomeDialogComponent, { ... });
    alert('Dialog opened!');
  }

  // Stub method for opening a snackbar
  openSnackbar(): void {
    // For demonstration, show an alert.
    // Or later replace with: this.snackBar.open('Snackbar message', 'Close', { duration: 3000 });
    alert('Snackbar opened!');
  }

}
