<mat-toolbar fxHide.xs>
  <div class="logo-block">
    <a class="logo" routerLink="/profile">
      <img src="/assets/images/tokn_logo.svg" height="34" alt="TokentoryLogo">
    </a>
    <mat-icon class="drawer-trigger" (click)="drawer.toggle()">menu</mat-icon>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">

    <ng-container *ngIf="isAuthenticated">
      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="progress.tx_hash && progress.percent < 100">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="menu-container">
          <p>Latest transaction is pending blockchain confirmation...</p>
          <div class="btn-border-container">
            <a mat-stroked-button color="accent"
               class="btn-border"
               [href]="settings.scanner_url + progress.tx_hash" target="_blank"
               *ngIf="settings$|async as settings"
            >
            {{settings.scanner_button_text|uppercase}}
            </a>
          </div>
        </div>
      </mat-menu>
      <div class="mat-progress-bar-container">
        <mat-progress-bar class="tokens-available {{progress.color}}"
                          [mode]="progress.percent > 0 && progress.percent < 100  ? 'indeterminate' : 'determinate'"
                          [value]="progress.percent">
        </mat-progress-bar>
      </div>
      <app-notifications-dropdown></app-notifications-dropdown>
      <div style="padding-left: 16px">

      <span class="profile-user" [matMenuTriggerFor]="userActions">
        {{username | async}}
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
        <mat-menu #userActions class="profile-user-menu" xPosition="before">
          <span mat-menu-item (click)="logout()">logout</span>
        </mat-menu>
      </div>
    </ng-container>


    <button *ngIf="!isAuthenticated" mat-flat-button color="primary" routerLink="/auth/sign-in">Login</button>
  </div>
</mat-toolbar>
<div class="drawer-wrapper">
<mat-drawer-container fxHide.xs class="example-container" [hasBackdrop]="false">
  <mat-drawer #drawer [mode]="'side'" opened>

    <div fxLayout="column" style="min-height: 100%" fxLayoutAlign="space-between stretch">
      <menu>
        <li *ngIf="!hasNotFilledField">
          <a [routerLink]="isAuthenticated ? '/wallet' : '/auth/sign-in'" routerLinkActive="current">
            <mat-icon svgIcon="wallet-icon"></mat-icon>
            wallet
          </a>
        </li>

        <li *ngIf="!hasNotFilledField">
          <a [routerLink]="isAuthenticated ? '/directory' : '/auth/sign-in'" routerLinkActive="current">
            <mat-icon svgIcon="directory-icon"></mat-icon>
            directory
          </a>
        </li>

        <li *ngIf="user?.is_creator && !hasNotFilledField">
          <a [routerLink]="isAuthenticated ? '/factory' : '/auth/sign-in'" routerLinkActive="current">
            <mat-icon svgIcon="tokentory-icon"></mat-icon>
            factory
          </a>
        </li>

        <li *ngIf="!hasNotFilledField">
          <a [routerLink]="isAuthenticated ? '/transactions' : '/auth/sign-in'" routerLinkActive="current">
            <mat-icon svgIcon="tx-history-icon"></mat-icon>
            tx history
          </a>
        </li>

        <li>
          <a [routerLink]="isAuthenticated ? '/profile' : '/auth/sign-in'" routerLinkActive="current">
            <mat-icon svgIcon="person-icon"></mat-icon>
            profile
          </a>
        </li>
      </menu>
      <div fxLayout="column" fxLayoutAlign="center start" class="bottom-menu">
        <a routerLink="/tokenomics">tokenomics</a>
        <a routerLink="/examples">blueprint</a>
        <a routerLink="/policies">policies</a>
        <a routerLink="/support">support</a>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content [class.empty-padding]="emptyPadding">
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
</div>
