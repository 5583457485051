  <app-public-toolbar [showSignupLink]="true"></app-public-toolbar>
  <div fxLayout="row">
    <div class="tokenomics-menu" fxHide.xs>
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <img src="assets/images/tokn_logo.svg" alt="Tokentory Logo">
      </div>
      <mat-divider class="menu-divider"></mat-divider>
      <mat-selection-list [multiple]="false" dense class="left-menu-list">
        <mat-list-option class="main-title-list-item" (click)="scroll(tokenomics)">Tokenomics</mat-list-option>
        <mat-list-option (click)="scroll(blockchain)">A New Kind of Digital Asset</mat-list-option>
        <mat-list-option (click)="scroll(buildingtrust)">Rebuilding Trust</mat-list-option>
        <mat-list-option (click)="scroll(societalShift)">Societal Shift</mat-list-option>

        <mat-list-option class="main-title-list-item" (click)="scroll(introduction)">Introducing TOKN</mat-list-option>
        <mat-list-option (click)="scroll(simplicity)">Simplicity Meets Blockchain</mat-list-option>
        <mat-list-option (click)="scroll(ecoSystem)">Your Own “Eco-System”</mat-list-option>
        <mat-list-option (click)="scroll(utility)">What Is a Utility Token?</mat-list-option>
        <mat-list-option (click)="scroll(selling)">Selling Your Tokens</mat-list-option>
        <mat-list-option (click)="scroll(trade)">Trade Tokens for Speculative Income</mat-list-option>
        <mat-list-option (click)="scroll(goods)">Sell Goods & Services for Tokens</mat-list-option>
        <mat-list-option (click)="scroll(offering)">What Is an Offering?</mat-list-option>
        <mat-list-option (click)="scroll(cycle)">The Token Cycle</mat-list-option>
      </mat-selection-list>
    </div>

    <div class="tokenomics-container">
      <div class="tokenomics-content">
        <h1 class="tokenomics-title" #tokenomics>Tokenomics</h1>

        <h2 class="tokenomics-sub-title" #blockchain>A New Kind of Digital Asset</h2>
        <p>For the first time, we can create a <strong>digital object</strong> that isn’t supervised by
          a single authority. It can be traded among <strong>infinite users</strong>, and it’s <strong>tamper-proof</strong>,
          <strong>counterfeit-proof</strong>, and <strong>traceable</strong> back to its creation—without relying on a third
          party for verification.
        </p>

        <p>
        <strong>How?</strong> Through <em>blockchain technology</em>: a shared ledger secured by cryptographic
        hashing. Each transaction references the one before it, forming an unbreakable chain. If anyone
        tries to alter past data without <strong>network consensus</strong>, the chain rejects it. This guarantees
        <strong>scarcity</strong> (limited supply) and <strong>immutability</strong>—two pillars of what makes “digital gold” possible.
        </p>

        <h2 class="tokenomics-sub-title" #buildingtrust>Rebuilding Trust</h2>
        <h2 class="tokenomics-sub-title__inner" #hype>Cutting Through the Hype</h2>
       <p>
        The term <strong>“blockchain”</strong> can sound intimidating or overhyped. During the crypto bubble, many
        projects abused the word to drive speculation—then collapsed, leaving people skeptical.
        That’s a loss for everyone, because <em>when properly used</em>, blockchain can radically simplify
        and secure how we transact online.
       </p>

        <p>
          At <strong>Tokn</strong>, we focus on <em>real value</em>: Blockchain provides <strong>scarce</strong>,
          <strong>immutable</strong> digital objects. The <em>people</em> decide what’s valuable—rather than marketing buzz.
        </p>

        <h2 class="tokenomics-sub-title" #societalShift>Societal Shift</h2>
        <h2 class="tokenomics-sub-title__inner" #followers>From Followers to Economies</h2>

        <p>
          Social media created a culture of <strong>influencers</strong> with millions of engaged followers.
          Traditional monetization—like ads and sponsorships—barely taps their economic potential.
        </p>
        <p>
          We believe influential creators can support <strong>mini economies</strong> around themselves.
          We provide the tools to form a <strong>scarce currency</strong>—and they give it value.
          It’s the next step in social media’s evolution: <strong>Social Currency</strong>.
        </p>

        <div class="spacer"></div>

        <h1 class="tokenomics-title" #introduction><span class="new">Introducing Tokn</span></h1>
        <h2 class="tokenomics-sub-title" #simplicity>Simplicity Meets Blockchain</h2>
        <p>
          Our goal is to make blockchain <strong>as easy as surfing the web</strong>. Under the hood, we handle
        all the complexity:</p>

          <ol>
            <li><strong>Human-readable accounts (e.g., username.tokn.eth)</strong>
            </li>
            <li><strong>One-click token creation and offerings</strong>
            </li>
            <li><strong>A built-in fiat-to-token payment system</strong>
            </li>
          </ol>
          <p>
            This forms a full marketplace where you can <strong>create a currency</strong>, <strong>offer goods/services</strong>
            for that currency, and <strong>buy/sell it for USD</strong>—all secured by a decentralized network.
          </p>
        <h2 class="tokenomics-sub-title" #ecoSystem>Your Own “Eco-System”</h2>
        <h2 class="tokenomics-sub-title__inner" #mint>Minting your own currency</h2>
        <p>
          A popular term in blockchain literature is “eco-system.” This is shorthand for
          economic system (not to be confused with an environmental ecosystem). An easy-
          to-understand eco-system is the ubiquitous state fair, where attendees buy tickets
          for drinks, food, and games at a single booth to help control and keep track of the
          money and expenses and to reduce losses caused by fraud. The tickets can be
          exchanged for items or events inside the fair. Blockchain technology allows for the
          creation of online economic systems similar to state fairs in concept, but much
          larger and more sophisticated in application. </p>
          
          <p>If you have or can develop a following
          of people, such as a group of social media followers or fans or readers of your
          journalism, Tokn allows for an easy, inexpensive way to generate a large
          amount of cash quickly by creating a token and a simple business plan to sell items
          to buyers of your tokens (said items include music, tickets to events, or anything
          else).
        </p>

        <h2 class="tokenomics-sub-title" #utility>What Is a Utility Token?</h2>
        <h2 class="tokenomics-sub-title__inner" #whatIsAToken>Scarcity & Fungibility</h2>
        <p>
          A <strong>Utility Token</strong> is a blockchain-based digital currency (e.g., on Ethereum or Polygon) representing an <em>asset or a service</em>. 
          Once you set a maximum supply, that can’t be changed, giving the token
          its <strong>scarcity</strong>.
        </p>
        <p>
          Each token is fungible (they’re interchangeable, like dollar bills), traceable back to its origin, and can’t be duplicated or altered. 
          Its value is driven by perceived demand — which could reflect how useful or how popular it is.
        </p>

        <h2 class="tokenomics-sub-title" #selling>Selling Your Tokens</h2>
        <h2 class="tokenomics-sub-title__inner" #raiseFunds>
          Raise Funds & Spread Your Brand
        </h2>
        <p>
          You can sell tokens initially in an <strong>ITO (Initial Token Offering)</strong> on Tokn. It only takes minutes
          to set up, though you may want a <em>marketing campaign</em> to boost visibility. Our human-readable
          URLs (like <strong>yourtoken.tokn.eth</strong>) make it easy to share on social media or anywhere else.
        </p>

        <h2 class="tokenomics-sub-title" #trade>Trade Tokens for Speculative Income</h2>
        <h2 class="tokenomics-sub-title__inner" #active>
          Active Market, Real Opportunities
        </h2>
        <p>
          Beyond the initial sale, Tokn supports <strong>active token trading</strong>, much like stock or currency
          markets. Users can buy low, sell high, and possibly profit if a token’s price rises. This
          <strong>volatility</strong> can offer both short- and long-term investment gains for those looking to trade.
        </p>

        <h2 class="tokenomics-sub-title" #goods>Sell Goods & Services for Tokens</h2>
        <h2 class="tokenomics-sub-title__inner" #recapture>
          Recapturing Tokens to Resell
        </h2>
        <p>
          By pricing <strong>exclusive merchandise, event tickets, or premium services</strong> in your token, you
          create added demand. Fans who bought early at a lower price can redeem these tokens
          for something valuable—while you <strong>recapture</strong> them and <strong>resell</strong> at current market rates.
          As your brand grows, the token’s value could climb, benefiting both you and your early adopters.
        </p>

        <h2 class="tokenomics-sub-title" #offering>What Is an Offering?</h2>
        <h2 class="tokenomics-sub-title__inner" #smartContract>
          Smart Contracts for Redemption
        </h2>
        <p>
          An <strong>Offering</strong> is a special contract that <em>accepts your token</em> as payment for a product or service.
          It’s tied only to your token—no other currency is valid there. Once the blockchain confirms the
          transaction, the tokens <strong>automatically move</strong> to your wallet, and the buyer receives a 
          <strong>receipt</strong> on-chain, proving their purchase.
        </p>

        <h2 class="tokenomics-sub-title" #cycle>The Token Cycle</h2>
        <h2 class="tokenomics-sub-title__inner" #create>
          Endless Possibilities
        </h2>
        <p>
          Tokn is a platform built on a few foundational capabilities listed below - 
          the building blocks to create a <strong>Token Cycle</strong>. The Token Cycle principles can be applied to endless use cases, 
          and while we provide a few (see <a routerLink="/examples"><em><u>Examples</u></em></a> tab) to get the ball rolling - 
          we haven't even scratched the surface in brainstorming ways in which the blueprint can be applied. 
          Thats where you guys come in! We are beyond excited to see the creative new ways our platform may be leveraged in the future.
        </p>
        <ol>
          <li><strong>Mint Your Token</strong>
            <ul>
              <li>Specify supply, name, etc.</li>
            </ul>
          </li>
          <li><strong>Offer It to Buyers</strong>
            <ul>
              <li>Sell tokens to the public in an ITO.</li>
            </ul>
          </li>
          <li><strong>List Offerings</strong>
            <ul>
              <li>Let holders redeem tokens for real goods/services.</li>
            </ul>
          </li>
          <li><strong>Recapture Tokens</strong>
            <ul>
              <li>Earn them back when people redeem.</li>
            </ul>
          </li>
          <li><strong>Resell Tokens</strong>
            <ul>
              <li>Re-list your tokens on Tokn’s exchange, potentially at higher prices.</li>
            </ul>
          </li>
        </ol>
        <p>
          Each new “mini economy” can connect to real-world currency (like USD), letting you scale your market globally.
        </p>
        <p>
          The possibilities are limitless, and as long as the tokens can be exchanged for
          tangible goods and services at some point – not security assets like stocks –
          commercialization of tokens can be done without regulation by the Securities and
          Exchange Commission.
        </p>
      </div>
    </div>

  </div>
