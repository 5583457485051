import { Component } from '@angular/core';
import { IconsService } from './shared-module/services/icons-service/icons.service';
import { ThemeService } from './theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // Default to dark theme
  isDarkTheme = true;
  title = 'ens-front';

  constructor(
    private iconService: IconsService,
    private themeService: ThemeService
  ) {
    // Set the initial theme on app load
    this.themeService.setTheme(this.isDarkTheme ? 'dark-theme' : 'light-theme');
  }

  // Toggle between dark and light themes
  toggleTheme(): void {
    this.isDarkTheme = !this.isDarkTheme;
    this.themeService.setTheme(this.isDarkTheme ? 'dark-theme' : 'light-theme');
  }
}