import { Component, OnInit } from "@angular/core";
import { SupportService } from "../../services/support.service";
import { SettingsService } from "../../services/settings/settings.service";

const examplesContent = [
  {
    title: "Exclusive Content Creators",
    text: `
      <h2>A. Overview</h2>
      <h3>Creators who specialize in subscription‐based or exclusive content can spin up their own personal “social currency” via Tokentory. This unlocks a closed‐loop economy around their fans:</h3>
      <ul>
        <li>Tokens serve as an entry point for premium content, live chats, or other exclusive perks.</li>
        <li>Offerings allow fans to redeem tokens for special services such as private streams, signed merchandise, or personalized content.</li>
      </ul>

      <h2>B. Creator’s Perspective</h2>
      <ol>
        <li><strong>Account Setup</strong>
          <ul>
            <li>Signs up (e.g., modeljane.tokentory.eth).</li>
            <li>Optional Business Account with custom domain (e.g., modeljane.eth).</li>
          </ul>
        </li>
        <li><strong>Token Creation</strong>
          <ul>
            <li>Sets name, handle, total supply (e.g., 100,000), decimals (18).</li>
            <li>All newly minted tokens appear in the creator's wallet.</li>
          </ul>
        </li>
        <li><strong>Initial Token Offering (ITO)</strong>
          <ul>
            <li>Lists a portion for sale at a set price.</li>
            <li>Fans buy directly on Tokentory; creator receives immediate proceeds.</li>
          </ul>
        </li>
        <li><strong>Creating Offerings (Exclusive Content)</strong>
          <ul>
            <li>Define how many tokens must be redeemed for each perk.</li>
            <li>Redeemed tokens return to the creator’s wallet for resale.</li>
          </ul>
        </li>
        <li><strong>Monetization and Growth</strong>
          <ul>
            <li>Token value can rise with creator’s popularity.</li>
            <li>Creator profits from both initial sales and re‐selling redeemed tokens.</li>
          </ul>
        </li>
      </ol>

      <h3>C. Token Buyer/Holder’s Perspective</h3>
      <ol>
        <li><strong>Buying Tokens</strong>
          <ul>
            <li>Fans buy “ModelJane” tokens in their Tokentory account using USD or crypto.</li>
          </ul>
        </li>
        <li><strong>Redeeming for Exclusive Content</strong>
          <ul>
            <li>Spend tokens for premium content, e.g. 1 token = 1 month of private feed access.</li>
          </ul>
        </li>
        <li><strong>Speculative Upside</strong>
          <ul>
            <li>If creator’s fame grows, token price may increase.</li>
          </ul>
        </li>
        <li><strong>Community and Utility</strong>
          <ul>
            <li>Tokens may grant membership perks or digital badges.</li>
          </ul>
        </li>
      </ol>
    `
  },
  {
    title: "Independent Artists",
    text: `
      <h3>A. Overview</h3>
      <p>Visual artists can leverage Tokentory to tokenize their brand, offering fans:</p>
      <ul>
        <li>Up‐front funding for new works.</li>
        <li>A patron‐artist dynamic, exchanging tokens for prints, originals, or commissions.</li>
      </ul>

      <h3>B. Creator’s Perspective</h3>
      <ol>
        <li><strong>Account & Token Setup</strong>
          <ul>
            <li>Creates painteralex.tokentory.eth, mints “alexart” token (e.g., 10,000 supply).</li>
          </ul>
        </li>
        <li><strong>Public Offering</strong>
          <ul>
            <li>Sells half the supply to fans at a chosen price.</li>
          </ul>
        </li>
        <li><strong>Creating Offerings (Exclusive Artwork)</strong>
          <ul>
            <li>Digital Prints: 5 tokens each, Commissions: 50 tokens, etc.</li>
            <li>Redeemed tokens flow back to the artist’s wallet for potential resale.</li>
          </ul>
        </li>
        <li><strong>Long‐Term Benefits</strong>
          <ul>
            <li>As reputation grows, token demand may rise, allowing further income from reselling captured tokens.</li>
          </ul>
        </li>
      </ol>

      <h3>C. Token Buyer/Holder’s Perspective</h3>
      <ol>
        <li><strong>Acquiring Artist Tokens</strong>
          <ul>
            <li>Buy tokens for artwork access or as a speculative investment.</li>
          </ul>
        </li>
        <li><strong>Redeeming for Artwork</strong>
          <ul>
            <li>Spend tokens on special Offerings for physical or digital art.</li>
          </ul>
        </li>
        <li><strong>Reselling Tokens</strong>
          <ul>
            <li>Can list them on Tokentory if demand spikes.</li>
          </ul>
        </li>
        <li><strong>Support & Engagement</strong>
          <ul>
            <li>Enjoy deeper connection with the artist’s journey and early access to new releases.</li>
          </ul>
        </li>
      </ol>
    `
  },
  {
    title: "Agencies",
    text: `
      <h3>A. Overview</h3>
      <p>Agencies can create separate tokens for each talent under one umbrella account, raising capital per talent and rewarding fans with exclusive experiences.</p>

      <h3>B. Creator’s (Agency’s) Perspective</h3>
      <ol>
        <li><strong>Business Account Setup</strong>
          <ul>
            <li>Agency (megastaragency.eth) controls multiple tokens for each artist or athlete.</li>
          </ul>
        </li>
        <li><strong>Token Minting per Talent</strong>
          <ul>
            <li>talent1.megastaragency.eth, talent2.megastaragency.eth, etc. with separate supplies.</li>
          </ul>
        </li>
        <li><strong>Offerings for Each Talent</strong>
          <ul>
            <li>Music star: Signed merch, VIP tickets; Sports talent: Meet & greet, jerseys.</li>
          </ul>
        </li>
        <li><strong>Monetization Loop</strong>
          <ul>
            <li>Initial Token Offerings raise capital, redeemed tokens flow back to the agency, can be resold.</li>
          </ul>
        </li>
        <li><strong>Talent & Agency Earnings</strong>
          <ul>
            <li>Rising popularity drives token demand, benefitting all stakeholders.</li>
          </ul>
        </li>
      </ol>

      <h3>C. Token Buyer/Holder’s Perspective</h3>
      <ol>
        <li><strong>Investing in the Talent</strong>
          <ul>
            <li>Buy tokens anticipating the star’s growth.</li>
          </ul>
        </li>
        <li><strong>Redeeming for Perks</strong>
          <ul>
            <li>Exclusive backstage passes, signed memorabilia, etc.</li>
          </ul>
        </li>
        <li><strong>Trading Tokens</strong>
          <ul>
            <li>Possibility of profit if token prices appreciate.</li>
          </ul>
        </li>
        <li><strong>Portfolio Approach</strong>
          <ul>
            <li>Spread investment across multiple talents or agencies on Tokentory.</li>
          </ul>
        </li>
      </ol>
    `
  },
  {
    title: "Exclusive Drop Commerce",
    text: `
      <h3>A. Overview</h3>
      <p>Brands built on “drop culture” can tokenize their name and restrict product access to token holders, fueling exclusivity and loyalty.</p>

      <h3>B. Creator’s (Brand’s) Perspective</h3>
      <ol>
        <li><strong>Account Setup</strong>
          <ul>
            <li>Brand registers brand.eth, mints e.g., brandtoken.brand.eth.</li>
          </ul>
        </li>
        <li><strong>Token Minting</strong>
          <ul>
            <li>Sets total supply (e.g., 1,000,000 tokens), releases in multiple drops.</li>
          </ul>
        </li>
        <li><strong>Exclusive “Drop” Offerings</strong>
          <ul>
            <li>X tokens per item, limited quantity, reacquire spent tokens for future sales.</li>
          </ul>
        </li>
        <li><strong>Market Effects</strong>
          <ul>
            <li>Each drop announcement may spike demand, raising token price.</li>
          </ul>
        </li>
      </ol>

      <h3>C. Token Buyer/Holder’s Perspective</h3>
      <ol>
        <li><strong>Early Access & Exclusivity</strong>
          <ul>
            <li>Buy tokens to secure limited releases or priority lines.</li>
          </ul>
        </li>
        <li><strong>Redemption</strong>
          <ul>
            <li>Use tokens during “drop day” to snag items before sellout.</li>
          </ul>
        </li>
        <li><strong>Speculative Hype</strong>
          <ul>
            <li>If the brand is in high demand, tokens might appreciate in value.</li>
          </ul>
        </li>
        <li><strong>Ongoing Relationship</strong>
          <ul>
            <li>Future collabs or events exclusive to token holders keep interest alive.</li>
          </ul>
        </li>
      </ol>
    `
  },
  {
    title: "Carbon Offset Credits",
    text: `
      <h3>A. Overview</h3>
      <p>Landowners, farmers, or governments can tokenize carbon offsets, letting companies buy verified credits for ESG compliance. The blockchain ensures traceability and a secondary market for trading.</p>

      <h3>B. Creator’s (Offset Provider’s) Perspective</h3>
      <ol>
        <li><strong>Account Setup</strong>
          <ul>
            <li>E.g., greenland.eth, recognized as the official carbon credit issuer.</li>
          </ul>
        </li>
        <li><strong>Token Creation (Carbon Credits)</strong>
          <ul>
            <li>Each token equates to 1 ton of CO₂ offset, total supply e.g., 1,000,000 tokens.</li>
          </ul>
        </li>
        <li><strong>Initial Offering (ITO)</strong>
          <ul>
            <li>Lists tokens at a set price; proceeds go to offset projects (farmers, reforestation, etc.).</li>
          </ul>
        </li>
        <li><strong>Redemption Mechanism</strong>
          <ul>
            <li>Corporations “retire” tokens to claim offsets; recorded permanently on-chain.</li>
          </ul>
        </li>
        <li><strong>Sustainability & Economic Benefit</strong>
          <ul>
            <li>Public ledger for ESG audits; potential re-issuance if offset programs expand.</li>
          </ul>
        </li>
      </ol>

      <h3>C. Token Buyer/Holder’s Perspective (Corporations, ESG Investors)</h3>
      <ol>
        <li><strong>Purchasing Credits</strong>
          <ul>
            <li>Companies buy tokens for carbon offset requirements.</li>
          </ul>
        </li>
        <li><strong>Offset (Redemption) Process</strong>
          <ul>
            <li>Retire tokens, removing them from circulation to avoid double-counting.</li>
          </ul>
        </li>
        <li><strong>Tracking & Compliance</strong>
          <ul>
            <li>Immutable blockchain transactions for transparent ESG reporting.</li>
          </ul>
        </li>
        <li><strong>Transparency & Reputation</strong>
          <ul>
            <li>Public proof of offset improves environmental credibility.</li>
          </ul>
        </li>
      </ol>
    `
  }
];

@Component({
  selector: "app-examples",
  templateUrl: "./examples.component.html",
  styleUrls: ["./examples.component.scss"],
})
export class ExamplesComponent implements OnInit {
  faq: { title: string; text: string }[] = [];

  constructor(
    private supportService: SupportService,
    private settings: SettingsService
  ) {
    // If you need to do replacements or dynamic changes, you can do so here.
    // Example: Replacing "Ethereum" with something from settings:
    // this.settings.settings$.subscribe((el) => {
    //   faqContent[1].text = faqContent[1].text.replace("Ethereum", el.title);
    // });
  }

  ngOnInit(): void {
    // If you load from an API, adapt as needed. If not, just use local content:
    this.supportService.getFAQ().subscribe(
      (res) => {
        // If the API returns data, use it; otherwise fallback to local array
        this.faq = res.results?.length ? res.results : examplesContent;
      },
      (err) => {
        this.faq = examplesContent; 
      }
    );
  }

  scroll(index: number) {
    document.getElementById(index.toString()).scrollIntoView();
  }
}