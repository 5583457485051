<!-- src/app/shared-module/components/styleguide/styleguide.component.html -->
<div class="styleguide-container">
  <!-- Typography -->
  <section class="typography">
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <h6>Heading 6</h6>
    <p>
      This is a sample paragraph to showcase your typography. It should inherit the base font settings and colors defined in your theme.
    </p>
  </section>

  <!-- Form Fields -->
  <section class="form-fields">
    <h2>Form Fields</h2>
    <mat-form-field appearance="outline" floatLabel="always" color="accent">
      <mat-label>Accent Input</mat-label>
      <input matInput placeholder="Accent">
      <span matSuffix>Suffix</span>
      <mat-hint>Hint text</mat-hint>
      <mat-error>Error message</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" color="primary">
      <mat-label>Primary Input</mat-label>
      <input matInput placeholder="Primary">
      <span matSuffix>Suffix</span>
      <mat-hint>Hint text</mat-hint>
      <mat-error>Error message</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" color="warn">
      <mat-label>Warn Input</mat-label>
      <input matInput placeholder="Warn">
      <span matSuffix>Suffix</span>
      <mat-hint>Hint text</mat-hint>
      <mat-error>Error message</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Textarea</mat-label>
      <textarea matInput placeholder="Enter text"></textarea>
    </mat-form-field>
    <div class="additional-controls">
      <div class="checkboxes">
        <mat-checkbox>Checkbox 1</mat-checkbox>
        <mat-checkbox>Checkbox 2</mat-checkbox>
      </div>
      <div class="radio-buttons">
        <mat-radio-group>
          <mat-radio-button value="1">Option 1</mat-radio-button>
          <mat-radio-button value="2">Option 2</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="slider">
        <mat-slider min="0" max="100" step="1" value="50"></mat-slider>
      </div>
      <div class="select">
        <mat-form-field appearance="outline">
          <mat-label>Select Option</mat-label>
          <mat-select>
            <mat-option value="a">Option A</mat-option>
            <mat-option value="b">Option B</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </section>

  <!-- Buttons -->
  <section class="buttons">
    <h2>Buttons</h2>
    <div class="button-group">
      <button mat-raised-button color="primary">Raised Primary</button>
      <button mat-raised-button color="accent">Raised Accent</button>
      <button mat-raised-button color="warn">Raised Warn</button>
    </div>
    <div class="button-group">
      <button mat-flat-button color="primary">Flat Button</button>
      <button mat-stroked-button color="primary">Stroked Button</button>
    </div>
    <div class="button-group">
      <button mat-icon-button color="primary">
        <mat-icon>favorite</mat-icon>
      </button>
      <button mat-fab color="primary">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-mini-fab color="primary">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div class="button-toggle">
      <mat-button-toggle-group appearance="standard">
        <mat-button-toggle value="1">Toggle 1</mat-button-toggle>
        <mat-button-toggle value="2">Toggle 2</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </section>

  <!-- Cards -->
  <section class="cards">
    <h2>Cards</h2>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Card Title</mat-card-title>
        <mat-card-subtitle>Card Subtitle</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>This is some sample content inside a card. Cards use the background and text colors defined by your theme.</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Action 1</button>
        <button mat-button>Action 2</button>
      </mat-card-actions>
    </mat-card>
  </section>

  <!-- Expansion Panels -->
  <section class="expansion-panels">
    <h2>Expansion Panels</h2>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Expansion Panel 1
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Content for the first expansion panel.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Expansion Panel 2
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Content for the second expansion panel.</p>
      </mat-expansion-panel>
    </mat-accordion>
  </section>

  <!-- Progress Indicators -->
  <section class="progress-indicators">
    <h2>Progress Indicators</h2>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </section>

  <!-- Tabs -->
  <section class="tabs">
    <h2>Tabs</h2>
    <mat-tab-group>
      <mat-tab label="Tab 1">
        <p>Content for Tab 1.</p>
      </mat-tab>
      <mat-tab label="Tab 2">
        <p>Content for Tab 2.</p>
      </mat-tab>
      <mat-tab label="Tab 3">
        <p>Content for Tab 3.</p>
      </mat-tab>
    </mat-tab-group>
  </section>

  <!-- Dialogs & Snackbars -->
  <section class="dialogs-snackbars">
    <h2>Dialogs &amp; Snackbars</h2>
    <!-- For dialogs, these buttons should call methods (to be implemented) that open a dialog/snackbar -->
    <button mat-raised-button color="primary" (click)="openDialog()">Open Dialog</button>
    <button mat-raised-button color="accent" (click)="openSnackbar()">Show Snackbar</button>
  </section>
</div>