import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core-module/guards/auth-guard/auth.guard';
import { BaseComponent } from './core-module/components/base/base.component';
import { StyleguideComponent } from './shared-module/components/styleguide/styleguide.component';
import { TokenomicsComponent } from './shared-module/components/tokenomics/tokenomics.component';
import { ExamplesComponent } from './shared-module/components/examples/examples.component';
import { FaqComponent } from './shared-module/components/faq/faq.component';
import { SupportFormComponent } from './shared-module/components/support-form/support-form.component';
import {FillProfileGuard} from './core-module/guards/fill-profile.guard';
import { PoliciesComponent } from './shared-module/components/policies/policies.component';
import { PrivacyPolicyComponent } from './shared-module/components/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './shared-module/components/cookie-policy/cookie-policy.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'profile',
  },
  {
    path: 'home',
    pathMatch: 'full',
    redirectTo: 'profile',
  },
  {
    path: 'tokenomics',
    component: TokenomicsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'examples',
    component: ExamplesComponent
  },
  {
    path: 'support',
    component: SupportFormComponent
  },
  {
    path: 'policies',
    component: PoliciesComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  { path: 'notifications-agreement', 
    redirectTo: 'policies', 
    pathMatch: 'full' 
  },

  {
    path: 'cookie-policy',
    component: CookiePolicyComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth-module/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: BaseComponent,
    canLoad: [AuthGuard],
    children: [
      {
        path: 'directory',
        loadChildren: () => import('./directory-module/directory.module').then(m => m.DirectoryModule),
        canActivate: []
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile-module/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'factory',
        loadChildren: () => import('./factory-module/factory.module').then(m => m.FactoryModule),
        canActivate: []
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transactions-module/transactions.module').then(m => m.TransactionsModule),
        canActivate: [FillProfileGuard]
      },
      {
        path: 'wallet',
        loadChildren: () => import('./wallet-module/wallet.module').then(m => m.WalletModule),
        canActivate: [FillProfileGuard]
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications-module/notifications.module').then(m => m.NotificationsModule),
        canActivate: [FillProfileGuard]
      },
      {
        path: 'qr-code-ticket',
        loadChildren: () => import('./qr-module/qr.module').then(m => m.QrModule),
        canActivate: [FillProfileGuard]
      }
    ]
  },
  /* TODO: DEVELOP GUARD */
  {
    path: 'styleguide',
    component: StyleguideComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
