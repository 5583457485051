import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../core-module/services/auth-service/auth.service';

@Component({
  selector: 'app-public-toolbar',
  templateUrl: './public-toolbar.component.html',
  styleUrls: ['./public-toolbar.component.scss']
})
export class PublicToolbarComponent implements OnInit {
  @Input() showSignupLink: boolean;
  @Input() hideAuthButtons = false;
  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }

}
