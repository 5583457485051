import { MatTooltipModule } from "@angular/material/tooltip";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StyleguideComponent } from "./components/styleguide/styleguide.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from "@angular/material/input";
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from "@angular/material/snack-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { DebounceClickDirective } from "./directives/debounce-click/debounce-click.directive";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { CdkTableModule } from "@angular/cdk/table";
import { ChartsModule } from "ng2-charts";
import { TrimPipe } from "./pipes/trim.pipe";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AreaChartComponent } from "./area-chart/area-chart.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { PurchaseOfferingComponent } from "./components/purchase-offering/purchase-offering.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { StrokedButtonComponent } from "./components/stroked-button/stroked-button.component";
import { SocialLinksComponent } from "./components/social-links/social-links.component";
import { MatChipsModule } from "@angular/material/chips";
import { WalletOfferingReceiptDialogComponent } from "./components/wallet-offering-receipt-dialog/wallet-offering-receipt-dialog.component";
import { NgxMaskModule } from "ngx-mask";
import { TokenomicsComponent } from "./components/tokenomics/tokenomics.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { PublicToolbarComponent } from "./components/public-toolbar/public-toolbar.component";
import { GradientContentWrapperComponent } from "./components/gradient-content-wrapper/gradient-content-wrapper.component";
import { MatListModule } from "@angular/material/list";
import { FaqComponent } from "./components/faq/faq.component";
import { ExamplesComponent } from "./components/examples/examples.component";
import { SupportFormComponent } from "./components/support-form/support-form.component";
import { MatSelectModule } from "@angular/material/select";
import { ScrollToElementDirective } from "./directives/scroll-to-element.directive";
import { Ng2CustomCarouselModule } from "ng2-custom-carousel";
import { PoliciesComponent } from "./components/policies/policies.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { CookiePolicyComponent } from "./components/cookie-policy/cookie-policy.component";
import { AsteriskDirective } from "./directives/asterisk/asterisk.directive";
import { NumberOnlyDirective } from "./directives/number-only/number-only.directive";
import { TermsNavigationDirective } from "./directives/terms-navigation/terms-navigation.directive";

@NgModule({
  declarations: [
    TermsNavigationDirective,
    NumberOnlyDirective,
    AsteriskDirective,
    DebounceClickDirective,
    StyleguideComponent,
    TrimPipe,
    AreaChartComponent,
    PurchaseOfferingComponent,
    StrokedButtonComponent,
    SocialLinksComponent,
    WalletOfferingReceiptDialogComponent,
    TokenomicsComponent,
    PublicToolbarComponent,
    GradientContentWrapperComponent,
    FaqComponent,
    ExamplesComponent,
    SupportFormComponent,
    ScrollToElementDirective,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    PoliciesComponent
  ],
  imports: [
    ChartsModule,
    FlexLayoutModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatChipsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    MatToolbarModule,
    RouterModule,
    MatListModule,
    MatSelectModule,
    Ng2CustomCarouselModule,
    MatSliderModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    TermsNavigationDirective,
    NumberOnlyDirective,
    AsteriskDirective,
    ChartsModule,
    DebounceClickDirective,
    FlexLayoutModule,
    /* Material modules */
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    CdkTableModule,
    TrimPipe,
    AreaChartComponent,
    PurchaseOfferingComponent,
    StrokedButtonComponent,
    SocialLinksComponent,
    WalletOfferingReceiptDialogComponent,
    ScrollToElementDirective,
    Ng2CustomCarouselModule,
    PublicToolbarComponent,
    MatSliderModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, verticalPosition: "top" },
    },
  ],
})
export class SharedModule {}
