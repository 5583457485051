import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private renderer: Renderer2;
  private currentTheme = 'dark-theme'; // default theme

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setTheme(theme: 'dark-theme' | 'light-theme'): void {
    const body = document.body;
    // Remove the current theme class and add the new one
    this.renderer.removeClass(body, this.currentTheme);
    this.renderer.addClass(body, theme);
    this.currentTheme = theme;
  }

  toggleTheme(): void {
    const newTheme = this.currentTheme === 'dark-theme' ? 'light-theme' : 'dark-theme';
    this.setTheme(newTheme);
  }
}