<app-public-toolbar [showSignupLink]="true"></app-public-toolbar>
<div fxLayout="row">
  <div class="policy-menu" fxHide.xs>
    <div fxLayout="row" fxLayoutAlign="space-between end">
      <img src="assets/images/tokn_logo.svg" alt="Tokentory Logo">
    </div>
    <mat-divider class="menu-divider"></mat-divider>
  </div>

  <div class="policy-container">
    <div class="policy-content">
      <h1 class="policy-title">Tokn User Agreement</h1>
      <div class="agreement-content">
        <p><strong>Last Updated:</strong> March 2025</p>
        <p>
          Welcome to Tokentory, a Wyoming-based platform for tokenized creator marketplaces.
          By accessing or using Tokentory (”Tokn”, "myTokn"), you agree to these Terms and Conditions (”Terms”).
          These Terms are designed to ensure legal protection for the platform and a fair, compliant
          marketplace for all users. Please read them carefully and ensure you comply with all provisions
          and applicable laws.
        </p>

        <h2><strong>1. Prohibited Activities</strong></h2>
        <p>
          Tokentory is committed to maintaining a legal, safe, and fair marketplace. Users shall not use the platform
          for any illegal or prohibited purposes. The following activities are expressly forbidden on Tokentory (this list
          is illustrative and not exhaustive):
        </p>
        <ul>
          <li>
            <strong>Money Laundering:</strong> Engaging in any transaction or scheme to conceal or disguise the origin of
            illicitly gained funds, or to make such funds appear legitimate. Any attempts to use Tokentory to wash money
            or fund illegal activities will result in immediate action.
          </li>
          <li>
            <strong>Fraudulent or Deceptive Schemes:</strong> Any form of fraud, misrepresentation, or deceit toward Tokentory
            or other users is prohibited. This includes impersonating others, creating fake profiles for scams, pyramid
            or Ponzi schemes, and any activity intended to defraud participants.
          </li>
          <li>
            <strong>Market Manipulation:</strong> Manipulating the token market is strictly forbidden. This includes wash trading,
            insider trading, front-running, pump-and-dump schemes, price fixing, or coordinated buying/selling intended to
            artificially influence token prices or trading volumes. Users must not collaborate to create false market activity
            or use non-public information to trade tokens unfairly.
          </li>
          <li>
            <strong>Illicit Goods/Services:</strong> Using Tokentory Tokens as a medium of exchange for illegal goods or services
            is prohibited. Users may not create tokens representing, or use tokens to facilitate transactions involving,
            narcotics, illegal weapons, trafficking, or any other unlawful product or service.
          </li>
          <li>
            <strong>Artificial Token Inflation/Abuse:</strong> Even if not rising to the level of a crime, any activity that
            artificially inflates token value or demand (such as sham transactions between colluding parties, false advertising
            to pump token price, or any form of market abuse) is prohibited and will be flagged for investigation by Tokentory’s
            compliance team. The platform monitors for unusual trading patterns and reserves the right to intervene to ensure a fair market.
          </li>
        </ul>
        <p>
          <strong>Enforcement:</strong> Engaging in any of the above prohibited activities will result in swift enforcement action.
          Tokentory may immediately suspend or terminate the offending user’s account and freeze any associated Tokens.
          The Company reserves the right to investigate suspected violations and will take appropriate legal action against
          users involved in illegal acts, which may include reporting to and cooperating with law enforcement authorities.
          Users found in violation may also forfeit any token holdings or gains associated with the prohibited conduct,
          at Tokentory’s discretion, and may be held liable for any damages or losses caused to the platform or other users.
        </p>
        <p>
          <strong>No Tolerance Policy:</strong> There is zero tolerance for illegal activity on Tokentory. Users are required
          to use the platform in accordance with all applicable laws and regulations. Tokentory will not hesitate to enforce these
          rules to maintain the integrity of the marketplace and to comply with legal obligations.
        </p>

        <h2><strong>2. No Refund &amp; Chargeback Policy</strong></h2>
        <ul>
          <li>
            <strong>All Sales Final:</strong> All purchases of Tokentory Tokens and related transactions are final.
            Tokentory maintains a strict no-refund and no-chargeback policy – once a token transaction is completed,
            it cannot be reversed or cancelled. Users understand and agree that they will not initiate chargebacks
            or request refunds for token purchases.
          </li>
          <li>
            <strong>Limited Exception Procedure:</strong> The only exceptions to this no-refund policy are in extraordinary
            circumstances such as clear evidence of fraud, extortion, or a legal mandate. In such rare cases, a user
            seeking a chargeback must submit a formal handwritten request explaining the situation and providing
            supporting evidence. This request should include strong justification (e.g., proof of identity theft, fraud,
            or a law enforcement directive) and must be signed by the user. Tokentory will conduct a thorough investigation
            of any such claim, exercising due diligence in reviewing evidence and, if necessary, cooperating with law
            enforcement authorities. Users acknowledge that submitting a request does not guarantee a refund; Tokentory
            will grant refunds or reverse transactions only if legally required or if the investigation definitively
            confirms egregious wrongdoing. Any decision regarding a refund or chargeback is at the sole discretion of Tokentory.
          </li>
          <li>
            <strong>Consequences of Unjustified Chargebacks:</strong> If a user attempts to circumvent this policy by initiating
            an unjustified chargeback (for example, through their bank or credit card issuer) without following the above
            procedure, Tokentory reserves the right to suspend or terminate the user’s account for violation of these Terms.
            Unauthorized chargebacks may be interpreted as fraudulent activity, and Tokentory will dispute such chargebacks
            with documentation of these Terms and the user’s agreement to them.
          </li>
        </ul>

        <h2><strong>3. KYC and AML Compliance</strong></h2>
        <p>
          To ensure compliance with international financial regulations, all Tokentory users must undergo Know Your Customer (KYC)
          verification as part of the onboarding process. By registering an account or participating in any token transaction,
          you agree to the following KYC/AML requirements:
        </p>
        <ul>
          <li>
            <strong>Identity Verification:</strong> Users are required to provide accurate personal information and verification documents
            during signup. This may include full legal name, date of birth, address, government-issued photo identification, proof of address
            (such as utility bills), and any other information Tokentory deems necessary to verify identity and satisfy Anti-Money Laundering (AML) rules.
            Tokentory may, at its discretion, request additional information at any time to reconfirm a user’s identity or source of funds.
          </li>
          <li>
            <strong>Third-Party Compliance Provider:</strong> Tokentory partners with a certified third-party compliance service to conduct KYC
            and AML checks on its behalf. By using Tokentory, you authorize us to share your provided information with these compliance agents
            for verification purposes. You may be required to agree to the third-party provider’s terms and conditions and privacy policy when
            submitting your documents. All personal data is handled in accordance with our Privacy Policy and applicable data protection laws.
          </li>
          <li>
            <strong>Ongoing AML Monitoring:</strong> Tokentory continuously monitors accounts and transactions for signs of money laundering,
            terrorist financing, fraud, or other suspicious activities. Transactions may be paused or reviewed if flagged by our systems.
            Tokentory adheres to all applicable AML laws and will report any suspicious activity or transactions to the appropriate authorities
            as required by law. Users are expected to cooperate with any additional due diligence requests and understand that Tokentory may be
            legally obligated to take certain actions (such as freezing funds or reporting) to comply with AML regulations.
          </li>
          <li>
            <strong>Failure to Comply:</strong> Providing false information or refusing to complete KYC will result in account restrictions.
            If a user does not complete the KYC process or if Tokentory suspects that the information provided is fraudulent or insufficient,
            Tokentory may deny platform access, block token transactions, or terminate the account. Users will be given notice to correct any deficiencies
            or provide missing information, but Tokentory reserves the right to suspend accounts until verification is successfully completed.
            In addition, if a user’s account raises AML concerns, Tokentory may suspend trading activity on that account pending further investigation.
          </li>
          <li>
            <strong>Periodic Updates:</strong> Users acknowledge that KYC is not a one-time event – Tokentory may require periodic re-verification
            or updated documentation to ensure continued compliance. For example, if regulations change or if a user’s transaction volume increases
            significantly, additional checks might be necessary. Failure to comply with any renewed KYC/AML request will be treated the same as an initial failure
            to comply, potentially resulting in loss of platform access.
          </li>
        </ul>
        <p>
          By using Tokentory, you confirm that all information you provide is truthful and up-to-date, and you agree to keep your account information current.
          We value the security of our community and comply with KYC/AML obligations to prevent illicit activities and protect all users.
        </p>

        <h2><strong>4. Obligations of Verified Token Creators</strong></h2>
        <p>
          Tokentory offers a Verified Token Creator program for creators who meet certain criteria, granting them a special verified status
          (similar to a verification badge) to enhance trust on the platform. Verified Creators enjoy benefits like priority support and potential fee discounts.
          In return for this status, Verified Creators must uphold additional obligations to maintain an active and reliable presence on Tokentory:
        </p>
        <ul>
          <li>
            <strong>Minimum Engagement and Offerings:</strong> Verified Creators are expected to remain active and continually engage their token holders.
            This includes a minimum level of offerings or participation on the marketplace. Creators should regularly introduce new perks, content,
            or token-based offerings (e.g., exclusive content, merchandise, services, experiences) to keep their community engaged.
            Tokentory may require Verified Creators to conduct a set number of Token Offerings or updates per month or year as a condition of maintaining verification.
            Failing to meet these offering frequency requirements can trigger a review of the creator’s verified status.
          </li>
          <li>
            <strong>Marketplace Participation:</strong> Verified Creators must actively participate in the Tokentory marketplace.
            This means responding to user inquiries, fostering community interactions, and not abandoning their token projects.
            Creators should avoid prolonged inactivity. Inactive listings or stagnation (no updates, no new content, no engagement for an extended period)
            may lead to penalties such as reduced visibility of the token, delisting of offerings, or loss of verified status.
            Tokentory will typically issue a warning to inactive Verified Creators, providing a grace period (e.g., 14 days) to improve engagement before enforcement actions are taken.
          </li>
          <li>
            <strong>Token Buyback Commitment:</strong> To protect token holders and the integrity of the marketplace, Verified Creators may be required
            to commit to a token buyback under certain conditions. For instance, if a Verified Creator decides to leave the platform or if their verified status
            is revoked due to non-compliance, Tokentory reserves the right to require the Creator to offer to buy back a significant portion of their circulating tokens
            from holders. This measure ensures that users are not left holding tokens for an inactive or uncommitted creator.
            The specifics of any buyback (price, amount, timeline) will be determined by Tokentory on a case-by-case basis, taking into account the interests of token holders and the circumstances of the creator’s departure or non-compliance.
          </li>
          <li>
            <strong>Additional Performance Standards:</strong> Tokentory may set other performance metrics for Verified Creators, such as maintaining a certain
            percentage of their token supply in a locked wallet or achieving a minimum trading volume. These standards, when applicable, will be communicated
            to the creator during the verification process. Verified Creators are also expected to abide by all other platform rules (including the Prohibited Activities and KYC/AML requirements above) and set a positive example within the community.
          </li>
          <li>
            <strong>Revocation of Verification:</strong> Verification is a privilege, not a right. Tokentory retains sole discretion to revoke a creator’s verified status
            if they fail to meet the above obligations or any verification criteria. If a Verified Creator breaches these Terms, engages in misconduct, or otherwise harms
            the Tokentory ecosystem, their verification badge can be removed without prior notice. In severe cases or repeated non-compliance, Tokentory may also suspend or
            terminate the creator’s account or token listings. The Company’s decision on verification status is final. Creators acknowledge that loss of verified status may impact their token’s
            visibility and user trust, and they agree that they will not make any claims against Tokentory for the consequences of such enforcement.
          </li>
        </ul>
        <p>
          By attaining Verified status, creators agree to these ongoing commitments. These obligations ensure that Verified Creators contribute meaningfully to the Tokentory platform,
          thereby fostering a thriving and trustworthy marketplace for all participants.
        </p>

        <h2><strong>5. Legal Compliance, Disclaimers, and Liabilities</strong></h2>
        <ul>
          <li>
            <strong>Compliance with Laws:</strong> Users of Tokentory must comply with all applicable laws and regulations in their local jurisdiction when accessing or using the platform.
            You represent that your use of Tokentory (including buying, selling, or creating Tokens) is legal in your jurisdiction. If you are located in a region where participation
            in token sales or cryptocurrency transactions is prohibited or restricted, you must not use this platform. Specifically, you confirm that you are not on any trade or economic
            sanctions list (such as the OFAC SDN list) and are not a citizen or resident of any country where use of Tokentory is embargoed or unlawful. It is the user’s responsibility to know
            and abide by their local laws; Tokentory is not liable for any consequences if a user breaks local laws by using the platform.
          </li>
          <li>
            <strong>Taxation:</strong> All tax obligations related to token purchases, sales, or other transactions on Tokentory lie solely with the user.
            Users are responsible for determining and fulfilling their own tax liabilities arising from their activities on the platform.
            Tokentory does not provide tax advice and will not withhold or report taxes on a user’s behalf. We strongly encourage users to consult with a tax professional
            to understand their tax duties regarding cryptocurrency and digital token transactions. By using Tokentory, you agree that Tokentory is not responsible for any tax issues or liabilities that may arise from your use of the platform.
          </li>
          <li>
            <strong>Tokens Are Not Securities or Investments:</strong> Tokentory Tokens are offered as utility tokens intended for engagement with Creators
            (such as accessing perks, content, or experiences), not as investment products. Tokens do not represent equity, ownership, shares, securities, or similar financial instruments
            in Tokentory or in any Creator’s business. Holding Tokentory Tokens does not entitle a user to profits, dividends, revenue sharing, or any monetary rewards from the Company or the Creator.
            Users should not purchase Tokens with an expectation of profit or price appreciation; any potential future value is speculative and uncertain. The platform makes no promises that Tokens
            will increase in value or have liquidity on secondary markets. By participating in token sales or trades, you acknowledge that you are not making an investment and you understand the risk that Tokens could lose some or all of their value.
          </li>
          <li>
            <strong>No Professional Advice:</strong> Any information provided by Tokentory or Creators (for example, about token functionality, potential perks, or roadmaps)
            is for general information purposes and does not constitute financial or legal advice. Tokentory is not an investment advisor, and users are responsible for making their own decisions on participating in any token offering.
            If you are unsure about participating, you should consult with a qualified advisor.
          </li>
          <li>
            <strong>Assumption of Risk:</strong> Users of Tokentory acknowledge and accept the inherent risks associated with blockchain tokens and online marketplaces.
            These risks include, but are not limited to: sudden changes in token value, lack of liquidity, technological vulnerabilities or hacks, regulatory changes that may affect token legality or value,
            and the possibility of project failure or creator abandonment. You agree that you use the Tokentory platform and purchase tokens at your own risk.
            The platform is provided on an “as is” and “as available” basis without warranties of any kind, either express or implied.
            Tokentory and its affiliates do not guarantee continuous, uninterrupted, or error-free access to the platform.
            All conditions, warranties, or other terms implied by law are excluded to the fullest extent permitted by applicable law.
          </li>
          <li>
            <strong>Limitation of Liability:</strong> To the maximum extent permitted by law, Tokentory (Tokn), its parent company Tokentory LLC,
            and all of its officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages
            arising out of or related to your use of (or inability to use) the platform or tokens. This includes, without limitation, damages for lost profits,
            lost data, loss of goodwill, business interruption, or other intangible losses, even if we have been advised of the possibility of such damages.
            In no event will Tokentory’s aggregate liability to any user exceed the total fees paid by that user to Tokentory in the six months prior to the event giving rise to the claim.
            Tokentory is not liable for the acts or omissions of third parties, such as payment processors, blockchain networks, or compliance providers, but we will reasonably cooperate with users in resolving issues caused by third-party services.
          </li>
          <li>
            <strong>No Liability for Creator Actions:</strong> Transactions on Tokentory often occur directly between Users and Creators (or between users in the secondary market).
            Tokentory is not a party to purchase agreements between buyers and sellers of Tokens, and thus Tokentory is generally not responsible for any misconduct,
            non-performance, or breach by any Creator or User in those transactions. Creators are independent and manage their token perks and obligations;
            any disputes or claims arising from a Creator’s failure to deliver promised perks are to be resolved between the Creator and the token holders.
            However, Tokentory may, at its discretion, intervene or facilitate communication in disputes to uphold platform integrity.
          </li>
          <li>
            <strong>Indemnification:</strong> Users agree to indemnify and hold harmless Tokentory and its affiliates from any claims, losses, liabilities,
            or expenses (including attorneys’ fees) arising out of the user’s violation of these Terms or any applicable law, or the user’s misuse of the platform.
            This means if your actions result in a legal claim or loss against Tokentory, you will cover all costs and damages incurred by the company.
            Tokentory will promptly notify you of any such claim and may elect to have you defend Tokentory in such matter, provided such defense is conducted in a legally acceptable manner.
          </li>
          <li>
            <strong>Governing Law and Dispute Resolution:</strong> These Terms shall be governed by and construed in accordance with the laws of the State of Wyoming, USA
            (without regard to conflict of law principles). Any disputes arising from or relating to these Terms or the Tokentory platform that cannot be resolved amicably
            shall be exclusively brought in the state or federal courts located in Wyoming. By agreeing to these Terms, you consent to the jurisdiction of these courts.
            Tokentory and users also mutually waive the right to a trial by jury in any such lawsuit to the extent permitted by law.
            (If you are a consumer in a jurisdiction that disallows such venue or waiver provisions, this provision may not apply to you to the extent of that prohibition.)
            Additionally, users waive the right to participate in any class action against Tokentory, meaning any claims must be pursued on an individual basis.
          </li>
          <li>
            <strong>Severability:</strong> If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction,
            that provision shall be severed, and the remaining provisions shall remain in full force and effect.
          </li>
          <li>
            <strong>No Waiver:</strong> Tokentory’s failure to enforce any right or provision of these Terms shall not constitute a waiver of future enforcement of that right or provision.
          </li>
          <li>
            <strong>Modifications to Terms:</strong> Tokentory may amend or update these Terms from time to time at its discretion.
            When we make material changes, we will notify users via the Tokentory platform or email and provide a reasonable notice period (generally 30 days)
            before the new terms take effect. Continued use of the platform after updates constitutes acceptance of the revised Terms.
            If you do not agree to the changes, you must discontinue use of Tokentory before the updated Terms apply.
          </li>
        </ul>
        <p>
          By using Tokentory (Tokn), you acknowledge that you have read, understood, and agree to all of the above Terms and Conditions.
          These Terms are designed to protect both the platform and its users, ensuring a fair, transparent, and compliant ecosystem.
          If you have any questions or require clarification on any section of these Terms, please contact us at
          <a href="mailto:legal@tokentory.com">legal@tokentory.com</a> before proceeding with using the platform.
          Maintaining a trustworthy marketplace is a shared responsibility – thank you for doing your part by adhering to these Terms.
        </p>
      </div>
    </div>
  </div>
</div>