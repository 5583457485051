<div class="toolbar" fxHide.xs>
  <ul>
    <li *ngIf="auth.isAuthenticated">
      <a routerLink="/home" (click)="$event.preventDefault();" routerLinkActive="current">home</a>
    </li>
    <li  *ngIf="!auth.isAuthenticated">
      <a routerLink="/auth/start-page" (click)="$event.preventDefault();" routerLinkActive="current">home</a>
    </li>
    <li>
      <a routerLink="/tokenomics" (click)="$event.preventDefault();" routerLinkActive="current">tokenomics</a>
    </li>
    <li>
      <a routerLink="/examples" (click)="$event.preventDefault();" routerLinkActive="current">blueprint</a>
    </li>
    <li>
      <a routerLink="/support" (click)="$event.preventDefault();" routerLinkActive="current">support</a>
    </li>
  </ul>

  <div *ngIf="!hideAuthButtons && !auth.isAuthenticated" class="auth-buttons">
    <a *ngIf="showSignupLink" routerLink="/auth/sign-up-invite-code" class="signup-link" (click)="$event.preventDefault();">sign-up</a>
    <button mat-flat-button color="primary" routerLink="/auth/sign-in">login</button>
  </div>

</div>
