<mat-sidenav-container class="sidenav-container">
  <!-- Slide-out navigation -->
  <mat-sidenav #sidenav mode="over" position="end" class="mobile-nav">
    <nav>
      <a (click)="sidenav.close()" [routerLink]="isAuthenticated ? '/profile' : '/auth/sign-in'" routerLinkActive="current">
        <mat-icon svgIcon="person-icon"></mat-icon>
        <span>Profile</span>
      </a>
      <a (click)="sidenav.close()" [routerLink]="isAuthenticated ? '/wallet' : '/auth/sign-in'" routerLinkActive="current" *ngIf="!hasNotFilledField">
        <mat-icon svgIcon="wallet-icon"></mat-icon>
        <span>Wallet</span>
      </a>
      <a (click)="sidenav.close()" [routerLink]="isAuthenticated ? '/directory' : '/auth/sign-in'" routerLinkActive="current" *ngIf="!hasNotFilledField">
        <mat-icon svgIcon="directory-icon" style="color: var(--app-primary-color)"></mat-icon>
        <span>Directory</span>
      </a>
      <a (click)="sidenav.close()" [routerLink]="isAuthenticated ? '/factory' : '/auth/sign-in'" routerLinkActive="current" *ngIf="!hasNotFilledField">
        <mat-icon svgIcon="tokentory-icon"></mat-icon>
        <span>Factory</span>
      </a>
    </nav>
    <div fxLayout="column" fxLayoutAlign="center" class="bottom-menu">
      <a routerLink="/tokenomics" target="_blank">tokenomics</a>
      <a routerLink="/examples" target="_blank">blueprint</a>
      <a routerLink="/policies" target="_blank">policies</a>
    </div>
  </mat-sidenav>

  <!-- Main content -->
  <mat-sidenav-content>
    <mat-toolbar class="fixed-toolbar" style="background-color: var(--app-bg-base); padding: 30px 15px;">
      <div class="left-icons">
        <button *ngFor="let a of actions$ | async" mat-icon-button (click)="actionClick($event,a)">
          <mat-icon style="color: var(--app-primary-color)">{{a.icon}}</mat-icon>
        </button>
      </div>
    
      <img src="/assets/images/logo_small.svg" height="34" alt="TokentoryLogo">
    
      <div class="right-icons">
        <app-notifications-dropdown *ngIf="isAuthenticated" style="margin-right: 15px;"></app-notifications-dropdown>
        <button routerLink="/auth/sign-in" mat-icon-button *ngIf="!isAuthenticated">
          <mat-icon>login</mat-icon>
        </button>
        <!-- Hamburger icon to toggle the sidenav -->
        <button mat-icon-button disableRipple (click)="sidenav.toggle()">
          <mat-icon style="color: var(--app-primary-color);">menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>

    <div class="content-container" style="overflow: auto; padding-bottom: 80px; background-color: var(--app-bg-base)">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>